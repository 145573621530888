import React, { useEffect } from "react";
import JSONInput from "react-json-editor-ajrm";
import { useParams, useSearchParams } from "react-router-dom";
import locale from "react-json-editor-ajrm/locale/en";
import { useState } from "react";
import { getServerSideProps } from "../services/marketinggetapi";
import { getOtprequest } from "../services/approvalapi";
import { getApprovalspecificschema } from "../services/approvalapi";
import { editCourseApi } from "../services/editCourseapi";
import Loader from "./loader/loading";
import { uploadMediaImages, getFolder } from "../services/newsapi";

function Viewprogramjson() {
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [propsdata, setPropsdata] = useState();
  const [courseRecommenderData, setCourseRecommenderData] = useState();
  const [editdata, setEditdata] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    specificdata().then((specificdata) => {
      setPropsdata(specificdata.data.specific.programSchema);
      setCourseRecommenderData(specificdata.data.specific.courseComparisonKeys);
      setLoading(false);
    });

    async function specificdata() {
      setLoading(true);
      return await getServerSideProps(id);
    }
  }, []);

  function isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }

    return false;
  }

  let onC = (e) => {
    console.log({ e }, "_____+++++");
    setEditdata({ programSchema: e.jsObject });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let body = editdata;
      const isvalid = isJson(body);
      if (!isvalid) {
        alert("Please Provide Valid Json");
        return;
      }
      console.log("++++++++++", body, "__________");
      const data = await editCourseApi(id, body);

      programJson().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        // console.log(maildata, ":::::::::::::::;;maildata");
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });

      async function programJson() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (editdata.isCourseComparisonChanges) {
        alert("Invalid Keys");
      } else {
        alert("Only use the white listed cdn url");
      }
    }
  };
  return (
    <>
      {loading && <Loader />}

      <div style={{ marginTop: "-35px" }}>
        <div className="programjsoninstruction">
          <ul>
            <h6>Rules For Edit the Program JSON:</h6>
            <li>
              1. Don’t change keys (Keys are of{" "}
              <spna style={{ color: "#3CA8EE", fontWeight: "bold" }}>Blue</spna>{" "}
              colors).
            </li>
            <li>
              <li>
                2. Editing this JSON will impact Tablet, Mobile, Website views
              </li>
              3. Don’t make a change unless you are sure (check with tech-team
              for clarification).
            </li>
            <li>
              4. If you are changing text - feel free to do the same - but make
              sure that text length before and after are similar to ensure that
              UI works properly.
            </li>
            {searchParams.get("courseComparison") != "true" ? (
              <>
                <li>
                  5. Don't use the external urls for images. Their host should
                  be one of ('webcdn.imarticus.org',
                  'cdn.pegasus.imarticus.org', 'imarticus.org',
                  'blog.imarticus.org')
                </li>

                <li>
                  6. Only whitelisted urls are allowed eg:
                  'webcdn.imarticus.org', 'cdn.pegasus.imarticus.org',
                  'imarticus.org', 'blog.imarticus.org'.
                </li>
                <li>7. Only use WEBP, SVG, JPG and PNG format images.</li>
              </>
            ) : (
              <>
                <li>
                  <b>8. Only use Below Keys</b>
                  <br />
                  <pre>
                    <p>{"{"}</p>
                    <p>"Domain": [],</p>
                    <p>'Type of Program': [],</p>
                    <p>'Target Job Roles': [],</p>
                    <p>Partnership: [],</p>
                    <p>'Institute Ranking': [],</p>
                    <p>Eligibility: [],</p>
                    <p>Duration: [],</p>
                    <p>Fees: '',</p>
                    <p>'Training Mode': [],</p>
                    <p>'Career Services': [],</p>
                    <p>Masterclasses: [],</p>
                    <p>'Hands-on activities': [],</p>
                    <p>'Alumni Status': [],</p>
                    <p>'Beyond Classroom': [],</p>
                    <p>'Topics Covered': [],</p>
                    <p>'Application Process': []</p>
                    <p>{"}"}</p>
                  </pre>
                </li>
              </>
            )}
            <li>
              9. If the{" "}
              <spna style={{ color: "green", fontWeight: "bold" }}>
                Green tick
              </spna>{" "}
              turn into{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>Red</span> then
              please don't send the approval request and contact to tech-team.
            </li>
          </ul>
        </div>

        {searchParams.get("courseComparison") == "true" ? (
          <form onSubmit={submitHandler}>
            <JSONInput
              id="a_unique_id"
              placeholder={courseRecommenderData}
              width={"80vw"}
              locale={locale}
              onChange={(e) =>
                setEditdata({
                  courseComparisonKeys: e.jsObject,
                  isCourseComparisonChanges: true,
                })
              }
              height=""
            />
            <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Request Approval
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={submitHandler}>
            <JSONInput
              id="a_unique_id"
              placeholder={propsdata}
              width={"80vw"}
              locale={locale}
              onChange={onC}
              height=""
            />
            <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Request Approval
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default Viewprogramjson;
