import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getServerSideProps } from "../services/marketinggetapi";
import { getLocationProgramPage } from "../services/locationProgram";
import "./css/editcourse.css";

function Viewcoursedetail() {
  let { id } = useParams();
  console.log(id);
  const [propsdata, setPropsdata] = useState("");
  const [programtag, setProgramtag] = useState([]);
  const [programCategory, setProgramCategory] = useState([]);
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);
  const [locationpages, setLocationpages] = useState("");
  const [url, setUrl] = useState("");
  const [disable, setDisable] = useState(true);
  const [salesGroup, setSalesGroup] = useState("");

  useEffect(() => {
    specificdata().then((specificdata) => {
      setPropsdata(specificdata.data.specific);
      setUrl(specificdata.data.specific.link);
      locationData(specificdata.data.specific).then((data) => {
        setLocationpages(data.data);
      });
      setProgramtag(specificdata.data.programtag);
      console.log({ programtag: specificdata.data.specific.tag });
      setProgramCategory(specificdata.data.specific.program);
      setSalesGroup(specificdata.data.salesGroup);
    });

    async function specificdata() {
      return await getServerSideProps(id);
    }

    async function locationData(propsdata) {
      return await getLocationProgramPage(propsdata.link);
    }
  }, []);

  useEffect(() => {}, [checkbox.length]);
  useEffect(() => {
    if (locationpages.length > 0) {
      setDisable(false);
    }
  }, [locationpages]);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      // console.log("It is checked!")
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      // console.log("It is unchecked!")
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        // console.log("In if")
        // console.log("checkbox ::: ", checkbox)
        arrayToDo = checkbox;
      } else {
        // console.log("second else")
        // console.log("checkbox ::: ", checkbox)
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }
  console.log("Checkbox :::::: ", checkbox);
  return (
    <div>
      <div className="main_container_edit_viewCourse">
        <div className="program_name_container">
          <div className="programnameplusbutton">
            <div>
              <h1 className="program_name">Program Name</h1>
              <h1
                style={{ fontSize: "21px", lineHeight: "24px", opacity: "75%" }}
              >
                {propsdata.programTitle}
              </h1>
            </div>
            {/* {locationpages.length > 0 ? ( */}
            <div style={{ position: "relative" }}>
              <Link
                to="/Viewprogramlocationpages"
                state={{ url }}
                style={{ textDecoration: "none" }}
              >
                <button className="viewlocationpage" disabled={disable}>
                  View Location Pages
                </button>
                {disable ? (
                  <p className="hovertextlocation">
                    Please connect with developer to add atleast one location
                    page
                  </p>
                ) : (
                  ""
                )}
              </Link>
            </div>
            {/* ) : (
              ""
            )} */}
          </div>
        </div>
        <div>
          <h1 className="program_page">Program Attributes</h1>
        </div>
        <div className="program_container">
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="leadsquareProgramKey"
              value={propsdata.leadsquareProgramKey}
            />
            <div className="program_container_box">
              <h2>Leadsquare Program Key</h2>
              <h1>{propsdata.leadsquareProgramKey}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="programTitle"
              value={propsdata.programTitle}
            />
            <div className="program_container_box">
              <h2>Program Title</h2>
              <h1>{propsdata.programTitle}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="SecondaryProgramTitle"
              value={propsdata.SecondaryProgramTitle}
            />
            <div className="program_container_box">
              <h2>Secondary Program Title</h2>
              <h1>{propsdata.SecondaryProgramTitle}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="programName"
              value={propsdata.programName}
            />
            <div className="program_container_box">
              <h2>Program Name</h2>
              <h1>{propsdata.programName}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="metaDescription"
              value={propsdata.metaDescription}
            />
            <div className="program_container_box">
              <h2>Meta Description</h2>
              <h1>{propsdata.metaDescription}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="metaKeyword"
              value={propsdata.metaKeyword}
            />
            <div className="program_container_box">
              <h2>Meta Keywords</h2>
              <h1>{propsdata.metaKeyword}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogTitle"
              value={propsdata.ogTitle}
            />
            <div className="program_container_box">
              <h2>Og:Title</h2>
              <h1>{propsdata.ogTitle}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogDescription"
              value={propsdata.ogDescription}
            />
            <div className="program_container_box">
              <h2>Og:Description</h2>
              <h1>{propsdata.ogDescription}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogUrl"
              value={propsdata.ogUrl}
            />
            <div className="program_container_box">
              <h2>Og:url</h2>
              <h1>{propsdata.ogUrl}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogImage"
              value={propsdata.ogImage}
            />
            <div className="program_container_box">
              <h2>Og:Image</h2>
              <h1>{propsdata.ogImage}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="applicationDeadline"
              value={propsdata.applicationDeadline || "---"}
            />
            <div className="program_container_box">
              <h2>Application Deadline</h2>
              <h1>{propsdata.applicationDeadline || "---"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="startDate"
              value={propsdata.startDate || "---"}
            />
            <div className="program_container_box">
              <h2>Start Date</h2>
              <h1>{propsdata.startDate || "---"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="tag"
              value={programtag.map((d) => d.label) || "---"}
            />
            <div className="program_container_box">
              <h2>Program Tags</h2>
              {programtag.length > 0 ? (
                <h1>{programtag.map((d) => d.label).join(",")}</h1>
              ) : (
                <h1>--</h1>
              )}
            </div>
          </div>
          {/* Program Category */}
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="program"
              value={programCategory || "---"}
            />
            <div className="program_container_box">
              <h2>Program Category</h2>
              {programCategory.length > 0 ? (
                <h1>{programCategory}</h1>
              ) : (
                <h1>--</h1>
              )}
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="location"
              value={propsdata.locationNames || "---"}
            />
            <div className="program_container_box">
              <h2>Program Locations</h2>
              <h1>
                {(propsdata &&
                  propsdata.locationNames &&
                  propsdata.locationNames.length > 0 &&
                  propsdata.locationNames.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="modeoflearning"
              value={propsdata.modeOfLearning || "---"}
            />
            <div className="program_container_box">
              <h2>Mode of Learning</h2>
              <h1>
                {(propsdata &&
                  propsdata.modeOfLearning &&
                  propsdata.modeOfLearning.length > 0 &&
                  propsdata.modeOfLearning.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="schedule"
              value={propsdata.schedule || []}
            />
            <div className="program_container_box">
              <h2>Schedule of Program</h2>
              <h1>
                {(propsdata &&
                  propsdata.schedule &&
                  propsdata.schedule.length > 0 &&
                  propsdata.schedule.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="typeOfCertification"
              value={propsdata.typeOfCertification || []}
            />
            <div className="program_container_box">
              <h2>Type of Certification</h2>
              <h1>
                {(propsdata &&
                  propsdata.typeOfCertification &&
                  propsdata.typeOfCertification.length > 0 &&
                  propsdata.typeOfCertification.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>

          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="experiences"
              value={propsdata.experiences}
            />
            <div className="program_container_box">
              <h2>Experiences</h2>
              <h1>
                {(propsdata &&
                  propsdata.experiences &&
                  propsdata.experiences.length > 0 &&
                  propsdata.experiences.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="qualifications"
              value={propsdata.qualifications}
            />
            <div className="program_container_box">
              <h2>Qualifications</h2>
              <h1>
                {(propsdata &&
                  propsdata.qualifications &&
                  propsdata.qualifications.length > 0 &&
                  propsdata.qualifications.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="brochureFileName"
              value={propsdata.brochureFileName}
            />
            <div className="program_container_box">
              <h2>Brochure Link</h2>
              <a
                href={propsdata.brochureFileName}
                target="_blank"
                style={{ fontWeight: 500, overflowWrap: "break-word" }}
              >
                {propsdata.brochureFileName
                  ? propsdata.brochureFileName
                  : "---"}
              </a>
            </div>
          </div>
          {/* placement report field */}
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="placementReportName"
              value={propsdata.placementReportName}
            />
            <div className="program_container_box">
              <h2>Placement Report Link</h2>
              <a
                href={propsdata.placementReportName}
                target="_blank"
                style={{ fontWeight: 500, overflowWrap: "break-word" }}
              >
                {propsdata.placementReportName
                  ? propsdata.placementReportName
                  : "---"}
              </a>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="optionalFormFields"
              value={propsdata.optionalFormFields}
            />
            <div className="program_container_box">
              <h2>Update Form Fields</h2>
              <p>Note: Changes will only affect IL-3.0 Form</p>
              <h1>
                {(propsdata &&
                  propsdata.optionalFormFields &&
                  propsdata.optionalFormFields.length > 0 &&
                  propsdata.optionalFormFields.join(", ")) ||
                  "--"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="salesGroup"
              value={propsdata.salesGroup || "---"}
            />
            <div className="program_container_box">
              <h2>Sales Group</h2>
              <h1>{(propsdata && propsdata.salesGroup) || "--"}</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        {checkbox && checkbox.length >= 2 ? (
          <div className="floater_edit">
            <p>{checkbox.length - 1} Field Selected</p>
            <Link
              to={`/Editcourse${propsdata._id}`}
              state={{ checkbox }}
              style={{ textDecoration: "none" }}
            >
              <button className="floater_button">
                <p>Edit</p>
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>

      {propsdata.programSchema ? (
        <div className="main_container_edit_viewCourse">
          <div className="main_container_edit_viewCourse_2screen">
            <h1 className="program_page_content">Program Content</h1>
            <div className="program_container_box_content">
              <h4>Program Content</h4>
              <div>
                <Link to={`/Viewprogramjson${propsdata._id}`}>
                  <button className="edit_program_json">
                    Edit Program Content
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {propsdata.courseComparisonKeys ? (
        <div className="main_container_edit_viewCourse">
          <div className="main_container_edit_viewCourse_2screen">
            <h1 className="program_page_content">Course Recommender Content</h1>
            <div className="program_container_box_content">
              <h4>Course Recommender Content</h4>
              <div>
                <Link
                  to={`/Viewprogramjson${propsdata._id}?courseComparison=true`}
                >
                  <button className="edit_program_json">Edit Content</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Viewcoursedetail;
