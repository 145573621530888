import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import {
  editCourseApi,
  BrochureApi,
  PlacementReportApi,
} from "../services/editCourseapi";
import { getApprovalspecificschema } from "../services/approvalapi";
import { getOtprequest } from "../services/approvalapi";
import { TagPicker } from "rsuite";
import { Nametorender, getParameterByName } from "../services/helper";
import {
  getProgramtag,
  getProgramCategoryAPI,
} from "../services/marketinggetapi";

import {
  getAllCenterLocation,
  getExperiences,
  getFormFields,
  getModeOfLearning,
  getQualification,
  getScheduleOfProgram,
  getTypeOfCertification,
} from "../services/locationapi";
import { editLocationProgram } from "../services/locationProgram";
import { getSalesGroup } from "../services/leaddistribution";

const ddstyle = {
  width: "220px",
  height: "35px",
  outline: "none",
  border: "1px solid #cfcfcf",
  borderRadius: "8px",
  background: "transparent",
};

function Editcourse() {
  const [options, setOption] = useState("");
  const [programCategory, setProgramCategory] = useState([]);
  const [locationOptions, setLocationOption] = useState([]);
  const [modeOfLearningOption, setModeOfLearningOption] = useState([]);

  const [scheduleOfLearningOption, setScheduleOfLearningOption] = useState([]);
  const [certificationTypeLearningOption, setCertificationTypeLearningOption] =
    useState([]);

  const [formFieldsOption, setFormFieldsOptionOption] = useState([]);
  const [experiencesOption, setExperiencesOption] = useState([]);
  const [qualificationOption, setQualificationOption] = useState([]);

  const [salesGroupArray, setSalesGroupArray] = useState("");
  let url = window.location.search;
  console.log({ url });
  let schema = getParameterByName("schema");
  schema = schema.trim();
  useEffect(() => {
    something.state.checkbox.forEach((data) => {
      if (data.name === "brochureFileName") {
        let body = { ...editdata };
        body["brochureFileName"] = data.value;
        setEditdata(body);
      } else if (data.name === "placementReportName") {
        let body = { ...editdata };
        body["placementReportName"] = data.value;
        setEditdata(body);
      }
    });

    getProgramCategory().then((data) => {
      setProgramCategory(data);
      setLoading(false);
    });

    async function getProgramCategory() {
      setLoading(true);
      return await getProgramCategoryAPI();
    }

    getPtag().then((data) => {
      setOption(data);
      // console.log(data);
      setLoading(false);
    });

    async function getPtag() {
      setLoading(true);
      return await getProgramtag();
    }

    getLocation().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setLocationOption(labelarray);
      setLoading(false);
    });

    async function getLocation() {
      setLoading(true);
      return await getAllCenterLocation();
    }

    modeOfLearning().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setModeOfLearningOption(labelarray);
      setLoading(false);
    });
    scheduleOfLearning().then((data) => {
      console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setScheduleOfLearningOption(labelarray);
      setLoading(false);
    });
    certificationOfLearning().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setCertificationTypeLearningOption(labelarray);
      setLoading(false);
    });
    programFormFields().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      console.log({ myyyyyyyyyyy: data.data });
      setFormFieldsOptionOption(labelarray);
      setLoading(false);
    });

    async function modeOfLearning() {
      setLoading(true);
      return await getModeOfLearning();
    }
    async function scheduleOfLearning() {
      setLoading(true);
      return await getScheduleOfProgram();
    }
    async function certificationOfLearning() {
      setLoading(true);
      return await getTypeOfCertification();
    }
    async function programFormFields() {
      setLoading(true);
      return await getFormFields();
    }

    // Code for Experiences Data
    experiencesData().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setExperiencesOption(labelarray);
      setLoading(false);
    });

    async function experiencesData() {
      setLoading(true);
      return await getExperiences();
    }

    // Code for Qualification Data
    qualificationData().then((data) => {
      // console.log(data.data, "locationdata");
      let labelarray = data.data.map((item) => ({
        label: item,
        value: item,
      }));
      setQualificationOption(labelarray);
      setLoading(false);
    });

    async function qualificationData() {
      setLoading(true);
      return await getQualification();
    }

    getSalesGroups().then((data) => {
      setLoading(false);
      setSalesGroupArray(data.data);
    });

    async function getSalesGroups() {
      setLoading(true);
      return await getSalesGroup();
    }
  }, []);

  let { id } = useParams();
  let something = useLocation();
  // console.log(something.state.checkbox, ":::::::::");

  let tagsArr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "tag") {
      let tags = something.state.checkbox[i].value;
      tagsArr = tags.split(",");
      break;
    }
  }

  let locationArr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "location") {
      let location = something.state.checkbox[i].value;
      locationArr = location.split(",");
      break;
    }
  }

  let modeoflearningarr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "modeoflearning") {
      let modeoflearning = something.state.checkbox[i].value;
      modeoflearningarr = modeoflearning.split(",");
      break;
    }
  }
  let scheduleoflearningarr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "schedule") {
      let schedule = something.state.checkbox[i].value;
      scheduleoflearningarr = schedule.split(",");
      break;
    }
  }
  let certificationTypeoflearningarr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "typeOfCertification") {
      let typeOfCertification = something.state.checkbox[i].value;
      certificationTypeoflearningarr = typeOfCertification.split(",");
      break;
    }
  }

  let programFormFieldsArray = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "optionalFormFields") {
      let optionalFormFields = something.state.checkbox[i].value;
      programFormFieldsArray = optionalFormFields.split(",");
      break;
    }
  }

  let experiencearr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "experiences") {
      let experience = something.state.checkbox[i].value;
      experiencearr = experience.split(",");
      break;
    }
  }

  let qualificationarr = [];
  for (let i = 0; i < something.state.checkbox.length; i++) {
    if (something.state.checkbox[i].name == "qualifications") {
      let qualification = something.state.checkbox[i].value;
      qualificationarr = qualification.split(",");
      break;
    }
  }

  const [modeOfLearning, setModeOfLearning] = useState();
  const [schedule, setSchedule] = useState();
  const [typeOfCertification, setTypeOfCertification] = useState();

  const [editdata, setEditdata] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [link, setLink] = useState();
  const [placementReportlink, setPlacementReportlink] = useState();

  const [value, setValue] = React.useState(tagsArr || []);
  const [newProgramCategory, setNewProgramCategory] = React.useState("");
  const [cacheData, setCacheData] = React.useState([]);
  const handleSelect = (value, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setCacheData([...cacheData, item]);
  };

  const [locationvalue, setLocationValue] = React.useState(locationArr || []);
  const [locationcacheData, setLocationCacheData] = React.useState([]);
  const handlelocationSelect = (locationvalue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setLocationCacheData([...locationcacheData, item]);
  };

  const [modoflearningvalue, setModeofLearningValue] = React.useState(
    modeoflearningarr || []
  );
  const [modeoflearningcacheData, setModeofLearningCacheData] = React.useState(
    []
  );

  const [scheduleValue, setSchedulevalue] = React.useState(
    scheduleoflearningarr || []
  );
  const [schedulecacheData, setSchedulevalueCacheData] = React.useState([]);

  const [typeOfCertificationvalue, setTypeOfCertificationvalue] =
    React.useState(certificationTypeoflearningarr || []);
  const [typeOfCertificationcacheData, setTypeOfCertificationCacheData] =
    React.useState([]);

  const handlemodeoflearningSelect = (modoflearningvalue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setModeofLearningCacheData([...modeoflearningcacheData, item]);
  };

  const handleSchedule = (scheduleValue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setSchedulevalueCacheData([...schedulecacheData, item]);
  };

  const handletypeofcertificationSelect = (modoflearningvalue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setTypeOfCertificationCacheData([...typeOfCertificationcacheData, item]);
  };

  const [formFieldvalue, setFormFieldValue] = React.useState(
    programFormFieldsArray || []
  );
  const [formFieldscacheData, setFormFieldsCacheData] = React.useState([]);
  const handleFormFieldSelect = (formField, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setFormFieldsCacheData([...formFieldscacheData, item]);
  };

  // Code For Experience Field data
  const [experiencevalue, setExperienceValue] = React.useState(
    experiencearr || []
  );
  const [experiencecacheData, setExperienceCacheData] = React.useState([]);
  const handleExperienceSelect = (modoflearningvalue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setExperienceCacheData([...experiencecacheData, item]);
  };

  // Code For Qualification Field data
  const [qualificationvalue, setQualificationValue] = React.useState(
    qualificationarr || []
  );
  const [qualificationcacheData, setQualificationCacheData] = React.useState(
    []
  );
  const handleQualificationSelect = (modoflearningvalue, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setExperienceCacheData([...experiencecacheData, item]);
  };

  const buttonClick = async (data) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("file", data);
    console.log(formdata, "cccccccc");
    let body = { ...editdata };
    const res = await BrochureApi(id, formdata, schema);
    if (res.status === 200) {
      setLink(res.data.data.newLink);
      body["brochureFileName"] = res.data.data.newLink;
      setEditdata(body);
      alert("Brochure Successfully Uploaded");
      setLoading(false);
    } else {
      alert(res.data.message);
      setLoading(false);
    }
  };

  const placementButtonClick = async (data) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("file", data);
    console.log(formdata, "cccccccc");
    let body = { ...editdata };
    const res = await PlacementReportApi(id, formdata, schema);
    if (res.status === 200) {
      setPlacementReportlink(res.data.data.newLink);
      body["placementReportName"] = res.data.data.newLink;
      setEditdata(body);
      alert("Placement Report Successfully Uploaded");
      setLoading(false);
    } else {
      alert(res.data.message);
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log("inside Handler");
      setLoading(true);
      let body = { ...editdata };
      console.log("body:::::::::", body, ":::::::body");
      if (value.length > 0) {
        body["tag"] = value;
        setEditdata(body);
      }

      if (locationvalue.length > 0) {
        body["locationNames"] = locationvalue;
        setEditdata(body);
      }

      if (newProgramCategory.length > 0) {
        body["program"] = newProgramCategory;
        setEditdata(body);
      }

      if (modoflearningvalue.length > 0) {
        body["modeOfLearning"] = modoflearningvalue;
        setEditdata(body);
      }
      if (scheduleValue.length > 0) {
        body["schedule"] = scheduleValue;
        setEditdata(body);
      }
      if (typeOfCertificationvalue.length > 0) {
        body["typeOfCertification"] = typeOfCertificationvalue;
        setEditdata(body);
      }

      if (formFieldvalue.length > 0) {
        body["optionalFormFields"] = formFieldvalue.filter(
          (data) => data.length > 0
        );
        setEditdata(body);
      }

      if (experiencevalue.length > 0) {
        body["experiences"] = experiencevalue;
        setEditdata(body);
      }
      if (qualificationvalue.length > 0) {
        body["qualifications"] = qualificationvalue;
        setEditdata(body);
      }
      console.log({ body });
      for (let i = 0; i < Object.keys(body).length; i++) {
        // console.log("1")
        if (body[Object.keys(body)[i]]) {
          console.log(typeof body[Object.keys(body)[i]], "trim function");
          if (typeof body[Object.keys(body)[i]] == "string") {
            if (body[Object.keys(body)[i]].trim() === "") {
              setLoading(false);
              return alert("Empty Field Found");
            }
          } else if (body[Object.keys(body)[i]].length == 0) {
            setLoading(false);
            return alert("Empty Field Found");
          }
        }
      }

      // if (something.state.checkbox.filter(
      //   (data) => data.name === "brochureFileName")) {
      //     body["brochureFileName"] = something.state.checkbox.filter(
      //       (data) => data.name === "brochureFileName"
      //     )[0].value;
      //   }
      // if (!link && body["brochureFileName"]) {
      //   delete body.brochureFileName;
      // }
      let data;
      if (schema.length > 4) {
        data = await editLocationProgram(id, body);
      } else {
        data = await editCourseApi(id, body);
      }

      sahil().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        // console.log(maildata, ":::::::::::::::;;maildata");
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      // (maildata)
      async function sahil() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Enter Field is less then the required Validation");
    }
  };

  const onchnage = function (e) {
    let body = { ...editdata };
    body[e.target.name] = e.target.value;

    setEditdata(body);
  };

  // console.log('====================================');
  // console.log(editdata, cacheData, value);
  // console.log('====================================');

  console.log({ salesGroupArray }, "lknlnln");
  return (
    <>
      {loading && <Loader />}

      <div className="main_container_edit">
        <div className="main_container_divider">
          <div className="main_container_divid">
            <div className="change_content">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#097252",
                }}
              >
                CHANGE CONTENT
              </p>
            </div>
            <form onSubmit={submitHandler}>
              <div>
                {something.state.checkbox
                  .filter((data, index) => index != 0)
                  .map((data) => {
                    return (
                      <div className="enquire_box_space">
                        <div className="form-inpt-contnr">
                          <p className="enquireFormplace">{data.name}</p>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <h1
                              style={{
                                fontSize: "18px",
                                opacity: ".75",
                                textWrap: "nowrap",
                              }}
                            >
                              PREVIOUS CONTENT
                            </h1>
                            &nbsp;&nbsp;&nbsp;
                            {data.name === "brochureFileName" ? (
                              data.value ? (
                                <a
                                  style={{ fontSize: "18px" }}
                                  href={data.value}
                                  target="_blank"
                                >
                                  {data.value}
                                </a>
                              ) : (
                                "---"
                              )
                            ) : data.name === "placementReportName" ? (
                              data.value ? (
                                <a
                                  style={{ fontSize: "18px" }}
                                  href={data.value}
                                  target="_blank"
                                >
                                  {data.value}
                                </a>
                              ) : (
                                "---"
                              )
                            ) : (
                              <p className="enquireFormplace2">{data.value}</p>
                            )}
                          </div>
                          {console.log(data.name)}
                          {data.name === "tag" ? (
                            <div>
                              <TagPicker
                                cacheData={cacheData}
                                value={value}
                                onChange={setValue}
                                onSelect={handleSelect}
                                style={{ width: 500 }}
                                placeholder="Select Tags"
                                data={options}
                              />
                            </div>
                          ) : data.name === "program" ? (
                            <div>
                              <span
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "5px",
                                  fontSize: "18px",
                                  opacity: ".75",
                                  textWrap: "nowrap",
                                }}
                              >
                                NEW CONTENT
                              </span>
                              <select
                                name="programcategory"
                                onChange={(e) =>
                                  setNewProgramCategory(e.target.value)
                                }
                                style={ddstyle}
                                id="programcategory"
                              >
                                <option value="">
                                  Select Program Category
                                </option>
                                {programCategory.map((value, index) => {
                                  return (
                                    <option
                                      selected={
                                        data.value === value.value
                                          ? true
                                          : false
                                      }
                                      value={value.value}
                                    >
                                      {value.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          ) : data.name === "location" ? (
                            <div>
                              <TagPicker
                                cacheData={locationcacheData}
                                value={locationvalue}
                                onChange={setLocationValue}
                                onSelect={handlelocationSelect}
                                style={{ width: 500 }}
                                placeholder="Select Tags"
                                data={locationOptions}
                              />
                            </div>
                          ) : data.name === "modeoflearning" ? (
                            <div>
                              <TagPicker
                                cacheData={modeoflearningcacheData}
                                value={modoflearningvalue}
                                onChange={setModeofLearningValue}
                                onSelect={handlemodeoflearningSelect}
                                style={{ width: 500 }}
                                placeholder="Select Mode of Learning"
                                data={modeOfLearningOption}
                              />
                            </div>
                          ) : data.name === "schedule" ? (
                            <div>
                              <TagPicker
                                cacheData={schedulecacheData}
                                value={scheduleValue}
                                onChange={setSchedulevalue}
                                onSelect={handleSchedule}
                                style={{ width: 500 }}
                                placeholder="Select Schedule of Learning"
                                data={scheduleOfLearningOption}
                              />
                            </div>
                          ) : data.name === "typeOfCertification" ? (
                            <div>
                              <TagPicker
                                cacheData={typeOfCertificationcacheData}
                                value={typeOfCertificationvalue}
                                onChange={setTypeOfCertificationvalue}
                                onSelect={handletypeofcertificationSelect}
                                style={{ width: 500 }}
                                placeholder="Select Type of Certificate"
                                data={certificationTypeLearningOption}
                              />
                            </div>
                          ) : data.name === "optionalFormFields" ? (
                            <div>
                              <TagPicker
                                cacheData={formFieldscacheData}
                                value={formFieldvalue}
                                onChange={setFormFieldValue}
                                onSelect={handleFormFieldSelect}
                                style={{ width: 500 }}
                                placeholder="Select fields to change in form"
                                data={formFieldsOption}
                              />
                            </div>
                          ) : data.name === "experiences" ? (
                            <div>
                              <TagPicker
                                cacheData={experiencecacheData}
                                value={experiencevalue}
                                onChange={setExperienceValue}
                                onSelect={handleExperienceSelect}
                                style={{ width: 500 }}
                                placeholder="Select Experiences"
                                data={experiencesOption}
                              />
                            </div>
                          ) : data.name === "qualifications" ? (
                            <div>
                              <TagPicker
                                cacheData={qualificationcacheData}
                                value={qualificationvalue}
                                onChange={setQualificationValue}
                                onSelect={handleQualificationSelect}
                                style={{ width: 500 }}
                                placeholder="Select Qualifications"
                                data={qualificationOption}
                              />
                            </div>
                          ) : data.name === "brochureFileName" ? (
                            <div>
                              <div>
                                <div className="program_container_box2">
                                  <h2>New Brochure Upload</h2>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      style={{ marginTop: "10px" }}
                                      type="file"
                                      accept="application/pdf"
                                      name="file"
                                      onChange={(e) => {
                                        buttonClick(e.target.files[0]);
                                      }}
                                    ></input>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h1
                                    style={{
                                      fontSize: "18px",
                                      opacity: ".75",
                                      textWrap: "nowrap",
                                    }}
                                  >
                                    NEW CONTENT
                                  </h1>

                                  {link ? (
                                    <a
                                      href={link}
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {link}
                                    </a>
                                  ) : (
                                    "---"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : data.name === "salesGroup" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "60px",
                              }}
                            >
                              <h1
                                style={{
                                  fontSize: "18px",
                                  opacity: ".75",
                                  textWrap: "nowrap",
                                }}
                              >
                                NEW CONTENT
                              </h1>

                              <select
                                defaultValue={data.value}
                                value={editdata[data.name]}
                                required
                                name={data.name}
                                onChange={onchnage}
                                className="form-inpt enquireFormInputedit"
                              >
                                <option value="" disabled selected>
                                  Select Sales Group
                                </option>
                                {salesGroupArray &&
                                  salesGroupArray.map((data, i) => {
                                    return (
                                      <option value={data._id} key={i}>
                                        {data.email}
                                      </option>
                                    );
                                  })}
                              </select>

                              {/* <input
                                defaultValue={data.value}
                                value={editdata[data.name]}
                                onChange={onchnage}
                                type="text"
                                className="form-inpt enquireFormInputedit"
                                name={data.name}
                                required
                                maxLength={10000}
                              /> */}
                            </div>
                          ) : data.name === "placementReportName" ? (
                            <div>
                              <div>
                                <div className="program_container_box2">
                                  <h2>New Placement Report Uploaded</h2>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      style={{ marginTop: "10px" }}
                                      type="file"
                                      accept="application/pdf"
                                      name="file"
                                      onChange={(e) => {
                                        placementButtonClick(e.target.files[0]);
                                      }}
                                    ></input>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h1
                                    style={{
                                      fontSize: "18px",
                                      opacity: ".75",
                                      textWrap: "nowrap",
                                    }}
                                  >
                                    NEW CONTENT
                                  </h1>

                                  {placementReportlink ? (
                                    <a
                                      href={placementReportlink}
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {placementReportlink}
                                    </a>
                                  ) : (
                                    "---"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "60px",
                              }}
                            >
                              <h1
                                style={{
                                  fontSize: "18px",
                                  opacity: ".75",
                                  textWrap: "nowrap",
                                }}
                              >
                                NEW CONTENT
                              </h1>
                              <input
                                defaultValue={data.value}
                                value={editdata[data.name]}
                                onChange={onchnage}
                                type="text"
                                className="form-inpt enquireFormInputedit"
                                name={data.name}
                                required
                                maxLength={10000}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="request_approval">
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                >
                  Request Approval
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editcourse;
